import React, {FunctionComponent} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import Theme from "../../styles/theme";

import logo from "../../../../content/assets/images/logo.png";

interface LogoProps {
  title: string;
}

const LogoImage = styled.img`
  max-height: 30px;
  width: 30px;
  margin-right: 45px;
  padding: 1px;
  background-color: ${Theme.layout.backgroundColor};
  border-radius: 15px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    margin-right: 15px;
  }
`;

const HomeLink = styled(Link)`
  align-self: center;
  height: 30px;
`;

const Logo: FunctionComponent<LogoProps> = ({title}) => {
  return (
    <HomeLink key="home" to={`/`}>
      <LogoImage src={logo} alt={title}/>
    </HomeLink>
  );
}
;

export default Logo;
